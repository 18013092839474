import type { Theme } from '@emotion/react'
import { css } from '@emotion/react'

import { breakpoint, size } from '@twisto/styles'

export const footer = css`
  text-align: center;
  width: 100%;
`

export const footerText = (theme: Theme) => css`
  margin-top: ${size[24]};
  color: ${theme.palette.neutral[700]};

  ${breakpoint.up('md')} {
    margin-top: ${size[40]};
  }
`
