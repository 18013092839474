import type { ReactElement, ReactNode } from 'react'

import { Trans } from '@lingui/react'

import { useAppConfig } from '@twisto/base'
import { Container } from '@twisto/components/atoms/container'
import { Divider } from '@twisto/components/atoms/divider'
import { Flex } from '@twisto/components/atoms/flex'
import {
  Headline,
  HeadlineIcon,
  HeadlineSubtitle,
  HeadlineTitle,
} from '@twisto/components/atoms/headline'
import { Link } from '@twisto/components/atoms/link'
import { Typography } from '@twisto/components/atoms/typography'
import { styleUtils } from '@twisto/styles'

import * as styles from './error.styles'

type Props = {
  icon: ReactElement
  title: ReactElement
  perex: ReactElement
  button: ReactNode
  footer: ReactElement
}

export const Error = ({ icon, title, perex, button, footer }: Props) => {
  const { support } = useAppConfig()

  return (
    <Container fullHeight size="tiny">
      <Flex fullHeight alignItems="center" direction="column" justify="center">
        <Headline css={styleUtils.margin.bottom.xl}>
          <HeadlineIcon>{icon}</HeadlineIcon>
          <HeadlineTitle>{title}</HeadlineTitle>
          <HeadlineSubtitle>{perex}</HeadlineSubtitle>
        </Headline>
        {button}
        <div css={[styles.footer, styleUtils.margin.top.xl]}>
          <Divider />
          <Typography
            css={[styleUtils.margin.bottom.md, styles.footerText]}
            variant="b3"
          >
            {footer}
          </Typography>
          <Link href={`mailto:${support.email}`}>
            <Trans id="errors.link.sendEmail" />
          </Link>
        </div>
      </Flex>
    </Container>
  )
}
