import { Trans } from '@lingui/react'

import { TextButton } from '@twisto/components/atoms/button'
import { NotFoundError as NotFoundCmp } from '@twisto/components/organisms/errors'

import { appRoutes } from 'customer/routes'

export const NotFoundError = () => (
  <NotFoundCmp
    button={
      <TextButton fullWidth to={appRoutes.overview.dashboard.path}>
        <Trans id="customer.notFound.goToOverview" />
      </TextButton>
    }
  />
)
