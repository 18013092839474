import type { ReactNode } from 'react'

import { Trans } from '@lingui/react'

import { BigGhost404Icon } from '@twisto/icons'

import { Error } from './error'

type Props = {
  button: ReactNode
}

export const NotFoundError = ({ button }: Props) => (
  <Error
    button={button}
    footer={<Trans id="errors.404.footer" />}
    icon={<BigGhost404Icon color="error" height="100%" width="100%" />}
    perex={<Trans id="errors.404.perex" />}
    title={<Trans id="errors.404.title" />}
  />
)
