import type { ReactNode } from 'react'

import type { NextPage } from 'next'

import { Container } from '@twisto/components/atoms/container'
import { Layout } from '@twisto/components/atoms/layout'

import { CustomerAppWrapper } from 'customer/components'

import { NotFoundError } from '../components'

const NotFound: NextPage = () => <NotFoundError />

NotFound.getLayout = function NotFoundLayout({
  children,
}: {
  children: ReactNode
}) {
  return (
    <CustomerAppWrapper section="notFound">
      <Layout grayBackground withinCustomerLayout variant="screen">
        <Layout variant="content">
          <Container fullHeight noPadding size="standard">
            {children}
          </Container>
        </Layout>
      </Layout>
    </CustomerAppWrapper>
  )
}

export default NotFound
